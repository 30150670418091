import React from 'react';
import { graphql } from 'gatsby';
import GatsbyLink from '../components/GatsbyLink'
import Layout from '../components/Layout';
import Previewable from '../components/Previewable';
import SEO from '../components/SEO';
import RelatedProducts from '../components/Related/RelatedProducts';
import RelatedProjects from '../components/Related/RelatedProjects';
import { RenderShortcode } from '../components/RenderShortcode';
import RenderContent from '../components/RenderContent'
import { PageHeader } from '../components/PageHeader';
import { Image } from '../components/Image';
import './product.scss';
import { decodeEntities, stripHtmlTags } from '../utils/helpers';
import Accordion from '../components/Accordion';
import { GlobalCta } from '../components/Acf/GlobalCta'
import { Content } from '../components/Acf/Content'
import ProductTabs from '../components/Products/ProductTabs'

const ProductPost = ( props ) => {
  const { data, location } = props;
  const { wordpressPost: post, wordpressWpSettings } = data;
  const { wordpressUrl } = wordpressWpSettings
  if (!post) return null;
  const { title, content, excerpt, yoast, categories, date, author, wordpress_id, acf, product_application } = post;
  const { tabbedContent, productDownloadLink, shortDescription, below_honeycomb, above_honeycomb, featureImage, accreditations } = acf
  const productLink = productDownloadLink && productDownloadLink.source_url ? {title: 'Download PDF' ,url: productDownloadLink.source_url, target: '_blank' } : null
  const relatedPostsCheck = post.acf.relatedProducts && post.acf.relatedProducts
  const relatedProjectCheck = post.acf.relatedProjects && post.acf.relatedProjects
  return (
    <Layout wordpressUrl={wordpressUrl}>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(wordpressWpSettings.title)}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        banner={null}
        location={location}
      />
      <PageHeader
        headerTitle={title}
        headerSubTitle={shortDescription}
        headerBackgroundImage={null}
        location={location}
        link={productLink}
        above={above_honeycomb}
        below={below_honeycomb}
        featureImage={featureImage}
      />
      <Content title={acf.title} content={acf.content} layout="half" />
      <section className="single-product">
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              {tabbedContent && tabbedContent.length > 0 &&
                <ProductTabs tabs={tabbedContent} applications={product_application} accreditations={accreditations}/>
              }
            </div>
          </div>
        </div>
      </section>
      <RelatedProjects title="Featured Projects" contentType="projects" postfilter={relatedProjectCheck} action={{ url: `/projects/` , title: 'View all' }}/>
      <RelatedProducts title="Related Products" contentType="products" postfilter={relatedPostsCheck} action={{ url: `/products/` , title: 'View all' }} listView={false} className="related-products"/>
      <GlobalCta/>
    </Layout>
  )
}

export default Previewable(ProductPost, 'products');

export const pageQuery = graphql`
  fragment ProductFields on wordpress__wp_products {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProductPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    wordpressAcfOptions {
      options {
        showPrice
        showQuantity
        pricePrefix
        priceSuffix
        priceDescription
        showProductNote
        quote
        quotePage
      }
    }
    wordpressPost: wordpressWpProducts(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_image_url {
        source_url
      }
      acf {
        tabbedContent {
          content
          otherTitle
          tabTitle {
            value
            label
          }
          performance {
            title
            content
          }
        }
        relatedProducts
        relatedProjects
        productDownloadLink {
          source_url
        }
        title
        shortDescription
        content
        accreditations {
          accreditation {
            term_id
          }
          certificate {
            source_url
          }
        }
        featureImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 70) {
                ... GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        below_honeycomb {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 670, quality: 70) {
                  ... GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        above_honeycomb {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 670, quality: 70) {
                  ... GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
      product_accreditation
      product_application
      product_category {
        wordpress_id
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`
